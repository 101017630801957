@import 'properties';
$boxColor: $colorSenary;
$boxHeight: 35px;
$boxPadding: 0;
#checkout {
    .customer_type_tabs {
        list-style-type: none;
        margin: 25px 0 60px;
        padding: 0;
        @include cf();
        li {
            margin-right: 10px;
            float: left;
            a {
                box-shadow: none;
                background: $colorSenary;
                color: $fontColor;
                &.selected,
                &:hover {
                    background: $colorPrimary;
                    color: #fff;
                }
                &.button {
                    border-radius: 0;
                    padding: 0 21px;
                }
                .fa {
                    margin-right: 8px;
                }
            }
        }
        
    }
    margin: 20px 0 0 0;
    .box {
        @include border-box();
        position: relative;
        padding: 40px $boxPadding 10px $boxPadding;
        margin: 0 0 30px 0;
        background: #fff;
        &.padding {
            padding: 50px 20px 10px;
        }
        h2.headline {
            @include border-box();
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            font-size: 15px;
            height: $boxHeight;
            line-height: $boxHeight;
            padding: 0 10px;
            background: $boxColor;
            color: $fontColor;
        }
    }
    #checkoutCartContent {
        form {
            table.products {
                width: 100%;
                tr td {
                    padding: 5px 10px 5px 0;
                }
                thead {
                    tr {
                        border-bottom: 1px solid $colorSenary;
                        height: 50px;
                        line-height: 50px;
                        margin-bottom: 20px;
                        td {
                            padding: 0 5px;
                            font-weight: 600;
                            text-transform: uppercase;
                        }
                    }
                }
                tbody {
                    tr {
                        height: 40px;
                        &:first-child {
                            td {
                                padding-top: 20px
                            }
                        }
                        &:nth-child(even) {
                            background: $colorSeptenary;
                        }
                        td {
                            vertical-align: middle;
                            &.image {
                                padding-left: 20px;
                                padding-right: 20px;
                                width: 41px;
                            }
                            &.name {
                            }
                            &.item-price {
                                width: 15.5%;
                            }
                            &.amount {
                                width: 15.5%;
                                input {
                                    width: 40px;
                                    text-align: center;
                                }
                            }
                            &.item-total {
                                width: 10%;
                            }
                            &.remove {
                                width: 10%;
                                padding-right: 10px;
                                .fa {
                                    @include transition(all 0.2s);
                                    &:hover {
                                        color: $colorPrimary;
                                    }
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
            table#bonusProducts {
                tr {

                    td {
                        padding-top: 10px;
                        &.name {
                            .campaign {
                                font-weight: 500;
                                color: $colorPrimary;
                                font-size: 12px;
                            }
                        }
                        &.item-total {
                            .original {
                                display: none;
                            }
                        }
                    }
                }
            }
            .updateButton,
            #cartTotal {
                margin-top: 30px;
            }
            #cartTotal {
                line-height: $buttonHeight;
            }
        }
    }
    #moduleCampaigncode {
        .successBox {
            color: #00cc00;
        }
        .errorBox {
            color: red;
        }
        #campaign-codes {
            label {
                display: block;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 4px;
            }
            .field {
                float: left;
            }
            .buttons {
                float: left;
                input {
                    margin: 0 0 0 10px;
                }
            }
        }
        #campaign_codes {
            margin: 10px 0 0 0;
            line-height: 2;
            font-weight: 600;
        }
    }
    #moduleVouchers {
        label {
            display: block;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 4px;
        }
        .fields {
            float: left;
        }
        .buttons {
            float: left;
            margin-left: 10px;
        }
        table {
            margin: 20px 0 0 0;
            tr {
                height: 25px;
                td {
                    &.ch_tot_label {
                        width: 130px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    #paymentOptions,
    #deliveryOptions {
        padding: 10px;
        ul {
            @include cf;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                padding: 0;
            }
        }
        li {
            @include equal-cols(4, 1%, 1%);
            box-sizing: border-box;
            transition: all 0.2s;
            min-height: 0 !important;
            &.active label {
                background: #f1f1f1;
            }
            label {
                display: block;
                border-radius: 5px;
                position: relative;
                height: 120px;
                padding: 10px;
                text-align: center;
                cursor: pointer;
                border: 1px solid #ccc;
                input {
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    z-index: 1;
                }
                .figure {
                    margin: 5px 0 10px 0;
                    height: 50px;
                    position: relative;
                    z-index: 1;
                    img {
                        max-height: 50px;
                        height: auto;
                        max-width: 80%;
                    }
                }
                .name {
                    font-weight: bold;
                    line-height: 1em;
                    height: 1em;
                    &, span {
                        font-size: 12px !important;
                    }
                }
                .price {
                    margin: 5px 0;
                }
                p {
                    font-size: 11px
                }
            }
        }
    }
    #paymentOptions {
        li {
            min-height: 160px;
        }
    }
    .customer_information,
    .delivery_address {
        width: 48%;
        float: left;
        table, thead, tbody, tr, td {
            width: 100%;
            display: block;
        }
        table {
            tr {
                margin-bottom: 5px;
                td {
                    position: relative;
                    input[type=text],
                    .select {
                        width: 100%;
                    }
                    .mandatory-symbol {
                        position: absolute;
                        right: 13px;
                        top: 0;
                        line-height: $inputHeight;
                        color: red;
                    }
                }
            }
        }
    }
    .delivery_address {
        float: right;
    }
    #deliveryAddressForm {
        margin-top: 20px;
    }
    #bonus-offers-wrapper {
        table.bonus-wrapper {
            tr {
                &.bonus-offer {
                    td {
                        padding-left: 0;
                    }
                }
                td {
                    padding-left: 20px;
                    vertical-align: middle;
                    h2 {
                        display: none;
                    }
                    p {
                        margin: 15px 0 5px 0;
                    }
                    &.bonus-offer-image {
                        padding-left: 20px;
                        padding-right: 20px;
                        width: 41px;
                        text-align: center;
                        .fa {
                            color: $colorSecondary;
                            font-size: 24px;
                            display: inline-block;
                            &.disabled {
                                color: $colorSenary;
                            }
                        }
                    }
                    &.bonus-step-td {
                        width: 65%;
                        .bonus_step_select {
                            position: relative;
                            width: 100%;
                            &:after {
                                content: '\f0d7';
                                font-family: 'FontAwesome';
                                position: absolute;
                                right: 17px;
                                top: 7px;
                                font-size: 14px;
                            }
                            ul {
                                @include border-box();
                            }
                            > a {
                                display: block;
                                width: auto;
                                height: $inputHeight;
                                line-height: $inputHeight;
                                background: #fff;
                                border: 1px solid #ccc;
                                color: #000;
                                font-size: 12px;
                                text-decoration: none;
                                text-align: center;
                            }
                            ul {
                                display: none;
                                list-style: none;
                                position: absolute;
                                top: 100%;
                                left: 0;
                                z-index: 100;
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                max-height: 275px;
                                overflow: scroll;
                                background: #fff;
                                border: 1px solid #ccc;
                                border-width: 0 1px 1px 1px;
                                li {
                                    padding: 0 10px;
                                    &:hover {
                                        background: #d0d0d0;
                                    }
                                    &:nth-child(even) {
                                        background: #eaeaea;
                                        &:hover {
                                            background: #d0d0d0;
                                        }
                                    }
                                    a {
                                        display: block;
                                        height: 55px;
                                        line-height: 55px;
                                        &.disabled {
                                            opacity: 0.3s;
                                        }
                                    }
                                    .figure {
                                        box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
                                        float: left;
                                        width: 35px;
                                        max-height: 35px;
                                        margin-right: 5px;
                                        background: #fff;
                                        text-align: center;
                                        padding: 5px;
                                        margin: 5px 0 0 0;
                                    }
                                    .name {
                                        font-weight: bold;
                                        float: left;
                                        width: 40%;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        margin-right: 10px;
                                        height: 45px;
                                        padding: 0 0 0 5px;
                                    }
                                    .stock {
                                        float: left;
                                        text-align: center;
                                        width: 5%;
                                    }
                                    .price {
                                        float: right;
                                        margin-right: 10px;
                                        font-weight: bold;
                                        color: $colorPrimary;
                                    }
                                    .price .original {
                                        padding-right: 10px;
                                        text-decoration: line-through;
                                        color: $colorQuaternary;
                                    }
                                }
                            }
                            &.active {
                                ul {
                                    display: block;
                                }
                            }
                        }
                    }
                    &.bonus-offer-price {
                        text-align: right;
                        padding-right: 20px;
                        .original {
                            text-decoration: line-through;
                            color: $colorQuaternary;
                        }
                        .reduced {
                            color: $colorPrimary;
                            font-size: 18px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
    .cartMessage,
    .cartTotal {
        width: 48%;
        float: left;
        height: 200px;
    }
    .order_message_container {
        height: 80px;
    }
    .cartMessage,
    .order_message_container {
        padding-bottom: 20px;
        textarea {
            width: 100%;
            height: 100%;
        }
    }
    .cartTotal {
        float: right;
    }
    #checkoutTotals {
        .disable {
            display: none;
        }
    }
    .checkoutBottom {
        @include cf();
    }
}

/* Responsive */
@include responsive(800px) {
    #checkout #bonus-offers-wrapper table.bonus-wrapper tr td.bonus-step-td .bonus_step_select ul li {
        .name,
        .stock,
        .price {
            font-size: 1.8vw;
        }
    }
}
@include responsive(700px) {
    #checkout {
        .customer_information,
        .delivery_address,
        .cartMessage,
        .cartTotal {
            width: 100%;
        }
        #paymentOptions,
        #deliveryOptions {
            li {
                @include equal-cols(2, 1%, 1%, 3);
            }
        }
    }
}
@include responsive(520px) {
    table.bonus-wrapper {
        &, tbody, tr, td {
            display: block;
            width: 100% !important;
        }
    }
    #checkout #bonus-offers-wrapper table.bonus-wrapper tr {
        td.bonus-offer-price {
            text-align: center;
        }
    }
    #checkout #bonus-offers-wrapper table.bonus-wrapper tr td.bonus-step-td .bonus_step_select ul li {
        .name,
        .stock,
        .price {
            font-size: 3vw;
        }
    }
}
@include responsive(640px) {
    #checkout {
        #checkoutCartContent form table.products,
        .bonus-wrapper {
            tr td {
                &.name {
                    padding-left: 5px;
                }
                &.image, &.bonus-offer-image {
                    display: none;
                }
            }
        }
        #moduleCampaigncode #campaign-codes,
        #moduleVouchers {
            .field {
                float: none;
                input {
                    width: 100%;
                }
            }
            .buttons {
                margin: 10px 0 0 0;
                float: none;
                #campaign_code_submit {
                    margin: 0;
                }
            }
        }
        #moduleVouchers {
            .fields {
                float: none;
                input {
                    width: 100%;
                }
                .buttons {
                    float: none;
                    button {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
        .checkoutBottom {
            .left {
                float: none;
            }
            input[type=submit] {
                width: 100%;
                margin: 20px 0 0 0;
            }
        }
    }
}

@include responsive(430px) {
    #checkout {
        #checkoutCartContent form table.products {
            tr td {
                &.name {
                    width: 50%;
                }
                &.image {
                    display: none;
                }
                &.item-price {
                    display: none;
                }
            }
        }
    }
}

@include responsive(440px) {
    #checkout {
        #paymentOptions,
        #deliveryOptions {
            li {
                @include equal-cols(1, 1%, 1%, 2);
            }
        }
    }
}
@include responsive(390px) {
    #checkout {
        #moduleCampaigncode #campaign-codes {
            .buttons {
                margin: 10px 0 0 0;
                width: 100%;
                input {
                    margin: 0 !important;
                    width: 100%;
                    &#removeCodes {
                        margin-top: 10px !important;
                    }
                }
            }
        }
        #moduleVouchers .buttons .button {
            width: 100%;
        }
    }
}
