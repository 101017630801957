@mixin transition($transition: all 0.3s) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin border-box($model: border-box) {
    -webkit-box-sizing: $model;
    -moz-box-sizing: $model;
    box-sizing: $model;
}

@mixin no_tap_color {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

@mixin listReset {
    margin: 0;
    padding: 0;
    list-style: none;
    >li {
        margin: 0;
    }
}

@mixin verticalAlign($number: -50%) {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY($number);
    -ms-transform: translateY($number);
    transform: translateY($number);
}

@mixin appearance($value: none) {
    -webkit-appearance: $value;
    -moz-appearance:    $value;
    appearance:         $value;
}

@mixin resetSelect(){
    @include appearance(none);
    background: none;
    display: block;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: 0;
    width: 108%;
    outline: 0;
    &::-ms-expand {
        display: none;
    }
}

@mixin clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin cf() {
    @include clearfix();
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}

@mixin responsive($width: 1024px) {
    @if $responsive {
        @media all and (max-width: $width) {
            @content
        }
    }
}

@mixin center {
    @include cf();
    margin: auto;
    @if $responsive {
        max-width: $pageWidth;
    } @else {
        width: $pageWidth;
    }
}

//EQUAL-COLS
@mixin equal-cols($cols: 3, $left: 0%, $bottom: 0, $lastCol: 0){
    @if $lastCol > 0 {&:nth-of-type(#{$lastCol}n + 1){margin-left: $left;}} //Set the left margin to the new value  }//If $last is greater than 0, reset the column to correct values
    float: left;
    margin-left: $left;
    margin-bottom: $bottom;
    width: ( 100% - ($left * ($cols - 1)) ) / $cols;
    &:nth-of-type(#{$cols}n + 1){margin-left: 0;} //Remove the left-margin on every columns that are at the most left.
}

@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------

@mixin display-flex() {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

%flexbox { @include display-flex(); }


@mixin inline-flex {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

%inline-flex { @include inline-flex; }

@mixin flex-direction($value: row) {
    @if $value == row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    } @else if $value == column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    } @else if $value == column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    } @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }
    -webkit-flex-direction: $value;
    -moz-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-wrap($value: nowrap) {
    // No Webkit Box fallback.
    -webkit-flex-wrap: $value;
    -moz-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

@mixin flex-flow($values: (row nowrap)) {
    // No Webkit Box fallback.
    -webkit-flex-flow: $values;
    -moz-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

@mixin order($int: 0) {
    -webkit-box-ordinal-group: $int + 1;
    -webkit-order: $int;
    -moz-order: $int;
    -ms-flex-order: $int;
    order: $int;
}

@mixin flex-grow($int: 0) {
    -webkit-box-flex: $int;
    -webkit-flex-grow: $int;
    -moz-flex-grow: $int;
    -ms-flex-positive: $int;
    flex-grow: $int;
}

@mixin flex-shrink($int: 1) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    -ms-flex-negative: $int;
    flex-shrink: $int;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    -moz-flex-basis: $value;
    -ms-flex-preferred-size: $value;
    flex-basis: $value;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {

    // Set a variable to be used by box-flex properties
    $fg-boxflex: $fg;

    // Box-Flex only supports a flex-grow value so let's grab the
    // first item in the list and just return that.
    @if type-of($fg) == 'list' {
        $fg-boxflex: nth($fg, 1);
    }

    -webkit-box-flex: $fg-boxflex;
    -webkit-flex: $fg $fs $fb;
    -moz-box-flex: $fg-boxflex;
    -moz-flex: $fg $fs $fb;
    -ms-flex: $fg $fs $fb;
    flex: $fg $fs $fb;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    -moz-justify-content: $value;
    justify-content: $value;
}

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    -moz-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    -moz-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    -moz-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------

@mixin keyframes( $animationName )
{
    @-webkit-keyframes $animationName {
        @content;
    }
    @-moz-keyframes $animationName {
        @content;
    }
    @-o-keyframes $animationName {
        @content;
    }
    @keyframes $animationName {
        @content;
    }
}
