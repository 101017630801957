@import "properties";
$height: 35px;
$backgroundInactive: #fff;
$borderInactive: #ddd;
$backgroundActive: #ddd;
$borderActive: #f69331;
$iconsColor: #999;
.nav_prodlist {
    @include cf();
    border: 1px solid $colorSenary;
    border-left: none;
    border-right: none;
    padding: 15px 0;
    height: $height;
    margin: 0 0 20px 0;
    overflow: hidden;
    font-size: 14px;
    &.bottom {
        padding: 0;
        border: none;
    }

    .sortLinks,
    .productsPerPage,
    .pagination {
        @include cf();
        label {
            display: block;
            float: left;
            line-height: $height;
            color: $fontColor;
        }

        .select {
            background: #fff;
            height: 25px;
            float: left;
            margin: 5px 0 0 12px;
            &:after {
                line-height: 25px;
            }
            select {
                height: 100%;
            }
        }
    }

    .sortLinks {
        float: left;
        .select {
            width: 118px;
        }
    }

    .productsPerPage {
        float: left;
        margin-left: 20px;

        .select {
            width: 80px;
        }
    }

    .pagination {
        float: right;
        text-align: right;
        margin: 0;
        a {
            cursor: pointer;
            &.button {
                display: inline-block;
                height: $height;
                width: 45px;
                text-align: center;
                color: #fff;
                background: $fontColor;
                box-shadow: none;
                .fa {
                    line-height: $height;
                    font-size: 12px;
                    margin-left: -5px;
                    margin-top: 2px;
                }
                &.prev {
                    border-radius: 17px 0 0 17px;
                    margin-right: 0;
                    .fa:before {
                        content: '\f053';
                    }
                }

                &.next {
                    border-radius: 0 17px 17px 0;
                    .fa:before {
                        content: '\f054';
                    }
                }

                &:hover {
                    background: $colorPrimary;
                }
            }
        }
        .pageText {
            margin-right: 15px;
            line-height: $height;
            color: $fontColor;
        }
        &.bottom {
            border-radius: $borderRadius;
            float: none;
            height: $height;
        }
    }
}

@include responsive(700px) {
    .pageSearch .nav_prodlist .productsPerPage {
        margin: 40px 0 0 0;
    }

    $padding: 10px;
    .nav_prodlist{
        @include border-box();
        height: auto;
        min-height: 45px;
        padding: $padding;
        position: relative;
        .productsPerPage,
        .sortLinks,
        .viewLinks,
        .pagination {
            width: auto;
            float: none;
            margin: 0;
            .select {
                float: right;
            }
            label {
                line-height: 30px;
            }
        }
        .sortLinks {
            margin: 40px 0 0 0;
        }
        .viewLinks + .productsPerPage {
            margin: 15px 0 0 0;
        }
        .pagination {
            position: absolute;
            top: 10px;
            right: $padding;
            float: right;
            width: auto;
            &.bottom {
                top: 0;
                right: 0;
            }
        }
    }
}
