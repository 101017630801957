@import 'properties';
.slideshow {
    position: relative;
    z-index: 1;
    &:hover {
        .change-slider {
            opacity: 1;
        }
    }
    .change-slider {
        $size: 34px;
        @include transition(all 0.3s);
        position: absolute;
        top: 50%;
        margin-top: $size / 2 * -1;
        z-index: 140;
        color: #fff;
        font-size: $size;;
        opacity: 0;
        &.cycle-prev {
            left: 20px;
        }
        &.cycle-next {
            right: 20px;
        }
        &:hover {
            opacity: 0.5;
        }
    }
    .cycle-pager {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 140;
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        span {
            $size: 12px;
            display: inline-block;
            width: $size;
            height: $size;
            margin: 0 5px 0 0;
            border-radius: 100%;
            background: #fff;
            color: transparent;
            cursor: pointer;
            &.cycle-pager-active {
                background: $colorPrimary;
            }
            &:hover {
                opacity: 0.5;
            }
        }
    }
    #cycle {
        width: 100%;
        .cycle-slide {
            width: 100%;
            a {
                display: block;
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}


@include responsive(500px) {
    .slideshow {
        display: none;
    }
}
