@import "properties";
$imageHeight: 250px;

body.pageStart {
    .randomCategoryProducts {
        @include cf();
        margin-top: 40px;
        .randomCategoryProduct {
            @include equal-cols(3, 2%, 0);
            height: 50%;
            .figure {
                height: 480px;
                cursor: pointer;
                position: relative;
                background-size: cover !important;
                &:before {
                    transition: all 0.2s;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #fff;
                    opacity: 0;
                    z-index: 1;
                }
                &:hover {
                    .product-info {
                        background-color: $colorPrimary;
                        .price {
                            span.original {
                                color: #fff;
                            }
                        }
                    }
                    &:before {
                        opacity: 0.2;
                    }
                }
            }
            .product-info {
                @include transition(all 0.2s);
                @include border-box();
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: rgba($fontColor, 0.8);
                color: #fff;
                padding: 14px;
                z-index: 0;
                .nameWrapper {
                    color: #fff;
                    height: auto;
                    margin-bottom: 11px;
                    text-transform: none;
                }
                .price {
                    margin-top: 0;
                    text-align: left;
                    height: auto;
                    span {
                        font-size: 24px;
                        &.original {
                            color: $colorQuaternary;
                            text-decoration: line-through;
                        }
                    }
                }
            }
            .randomCategory {
                @include border-box();
                cursor: pointer;
                text-align: center;
                padding: 20px 0;
                margin-top: 30px;
                border: 1px solid $colorSenary;
                border-left: none;
                border-right: none;
                height: 112px;
                &:hover {
                    h3 {
                        color: $colorPrimary;
                        text-decoration: underline;
                    }
                }
                h3 {
                    @include transition(all 0.2s);
                    margin-bottom: 10px;
                }
                p {
                    color: $colorPrimary;
                }
            }
        }
    }
    .product-box .figure {
        height: $imageHeight;
    }
}
@if $split {
    $imageHeight: 175px;
}
.product-box {
    position: relative;
    .fav-list {
        .fa {
            transition: all 0.2s;
            position: absolute;
            top: 20px;
            left: 20px;
            width: 35px;
            height: 35px;
            cursor: pointer;
            border-radius: 50%;
            background: $colorSenary;
            color: $fontColor;
            line-height: 35px;
            font-size: 16px;
            display: inline-block;
            text-align: center;
            margin-right: 20px;
            z-index: 2;
            &:hover {
                background: $colorPrimary;
                color: #fff;
            }
        }
    }
    a {
        display: block;
    }
    .figure {
        position: relative;
        height: $imageHeight;
        a {
            height: 100%;
            &:before {
                @include transition(all 0.2s);
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #fff;
                opacity: 0;
                z-index: 1;
            }
            &:hover {
                &:before {
                    opacity: 0.2;
                }
            }
        }
        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            max-width: 100%;
            height: auto;
        }
    }
    .nameWrapper {
        height: 60px;
        overflow: hidden;
        .name {
            width: 100%;
            margin-top: 20px;
            font-weight: 200;
        }
    }
    .price {
        margin: 20px 0;
        height: 20px;
        span {
            $fontSize: 24px;
            font-size: $fontSize;
            font-weight: 400;
            &.reduced {
                color: $colorPrimary;
                margin-right: 5px;
            }

            &.original {
                text-decoration: line-through;
                color: $colorQuaternary;
            }
        }
    }
    .buttons {
        display: table;
        width: 100%;
        .watch_product,
        .moreInfo,
        .js-add-to-cart-button {
            display: table-cell;
        }
        .watch_product,
        .js-add-to-cart-button {
            width: 50px;
        }
    }
    a.button {
        border-radius: 0;
        display: block;
        margin: 0;
        text-align: center;
        padding: 0;
        box-shadow: none;
        &.moreInfo {
            background-color: $colorPrimary;
            color: #fff;
            &:hover {
                background-color: $colorSecondary;
            }
        }
        &.watch_product,
        &.js-add-to-cart-button {
            color: #fff;
            background-color: $fontColor;
            position: relative;
            &:before {
                transition: all 0.2s;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #fff;
                opacity: 0;
            }
            &:hover {
                &:before {
                    opacity: 0.2;
                }
            }
        }
    }
}

.pageCategory {
    .mainContent.split {
        .product-box {
            @include equal-cols(3, 2%, 2%);
        }
    }
}

.product-box {
    @include equal-cols(4, 1.65%, 40px);
}

@if $split {
    .relatedProducts .product-box,
    .subContent.rightContent .product-box {
        @include equal-cols(3, 2%, 40px);
        .figure {
            height: 235px;
        }
    }
}

.product-list-wrapper {
    .product-list-item {
        @include cf();
        position: relative;
        background: #fff;
        padding: 10px;
        border: 1px solid #ccc;
        border-top: none;
        &:first-child {
            border: 1px solid #ccc;
        }
        &:nth-child(even) {
            background: #f5f5f5;
        }
        .figure {
            float: left;
            padding-right: 20px;
        }
        .wrapper {
            @include cf();
            float: left;
            width: 350px;
            .name {
                display: block;
                line-height: 1;
                height: 32px;
            }
            .stock {
                float: left;
                padding-right: 10px;
                border-right: 1px solid #ccc;
            }
            .price {
                float: left;
                padding-left: 10px;
            }
        }
        .product-button {
            @include verticalAlign();
            right: 10px;
        }
    }
}
@media 
    screen and (min-width: 900px) and  (max-width: 1060px),
    screen and (min-width: 630px) and  (max-width: 830px),
    screen and (max-width: 560px) {
    .product-box .price span {
        font-size: 18px;
    }
}

@media 
    screen and (min-width: 600px) and  (max-width: 630px) {
    .product-box .price span {
        font-size: 17px;
    }
}

@include responsive(440px) {
    .product-box .price span {
        font-size: 3.7vw;
    }
}

@include responsive(960px) {
    body.pageStart .randomCategoryProducts .randomCategoryProduct {
        text-align: center;
        @include equal-cols(1, 2%, 2%, 3);
        .product-info .price {
            text-align: center;
        }
    }
}

@include responsive(900px) {
    .product-box {
        @include equal-cols(3, 2%, 2%, 4);
    }
}

@include responsive(600px) {
    .subContent.rightContent .product-box,
    .product-box {
        @include equal-cols(2, 2%, 10%, 3);
        .figure {
            height: 50vw;
        }
    }
}

@include responsive(580px) {
    .product-list-wrapper {
        .product-list-item {
            .wrapper {
                width: 200px;
                font-size: 11px;
            }
        }
    }
}

@include responsive(410px) {
    .product-list-wrapper {
        .product-list-item {
            padding-bottom: 50px;
            .figure {
                width: 20%;
                padding-right: 5%;
            }
            .wrapper {
                width: 75%;
            }
            .product-button {
                top: initial;
                right: initial;
                transform: none;
                left: 25%;
                bottom: 10px;
            }
        }
    }
}

@include responsive(320px) {
    .subContent.rightContent .product-box,
    .product-box {
        .watch_product,
        .js-add-to-cart-button {
            width: 40px;
        }
    }
}
