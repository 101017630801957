@import "properties";
.productPage {
    $thumbSize: 84px;
    $numberOfThumbnails: 5;
    @if $split {
        $thumbSize: 70px;
        $numberOfThumbnails: 4;
    }
    margin-top: 20px;
    p {
        font-size: $fontSize;
    }
    .productHeader {
        @include cf();
    }
    .images {
        float: left;
        width: 42.374%;
        .image {
            position: relative;
            width: 100%;
            .imageWrap,
            a {
                width: 100%;
                height: 100%;
                display: block;
                text-align: center;
            }
        }
        .thumbnails {
            @include cf();
            .thumb {
                @include border-box();
                position: relative;
                float: left;
                width: $thumbSize;
                height: $thumbSize;
                margin: 20px 0 0 20px;
                overflow: hidden;
                cursor: pointer;
                &:nth-child(#{$numberOfThumbnails}n + 1) {
                    margin-left: 0;
                }
                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    .info {
        float: right;
        width: 46.611%;
        h1 {
            font-size: 24px;
            margin-bottom: 7px;
        }
        .price-text {
            font-size: 14px;
            font-weight: 200;
        }
        .price {
            font-size: 30px;
            margin: 5px 0 20px 0;
            font-weight: 400;
            span {
                &.reduced {
                    color: $colorPrimary;
                }
                &.original {
                    color: $colorQuaternary;
                    text-decoration: line-through;
                }
            }
        }
        .monthlyKlarnaString {
            margin-top: 20px;
        }
        #alternatives {
            p.row {
                margin-bottom: 20px;
                label {
                    display: block;
                    line-height: 1;
                    margin-bottom: 5px;
                }
                .select {
                    width: 100%;
                }
            }
        }
        .buy-button,
        .fav-button {
            box-shadow: none;            
        }
        #add-to-cart {
            @include cf();
            margin-bottom: 30px;
            input[type=text] {
                width: 14.13%;
                float: left;
                text-align: center;
                padding: 0;
                font-size: $fontSize;
                color: $fontColor;
            }
            input[type=submit] {
                width: 75%;
                float: right;
                padding: 0
            }
        }
        .buy-button {
            color: #fff;
            background: $colorSecondary;
            &:hover {
                background: $colorSecondary;
                opacity: 0.8;
            }
        }
        .fav-button {
            color: $fontColor;
            background: $colorSenary;
            padding: 0;
            width: 35px;
            box-shadow: none;
            margin-left: 10px;
            &:hover {
                color: #fff;
                background: $colorPrimary;
            }

        }
        .shortDescription {
            margin: 0;
            padding: 20px 0;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }
        .productInfo {
            padding: 20px 0;
            border-bottom: 1px solid #ccc;
            p {
                @include cf();
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                .left {
                    float: none;
                }
                .right {
                    float: none;
                    .stock-level {
                        &:before {
                            font-family: FontAwesome;
                            font-size: 14px;
                        }
                        &.in-stock {
                            color: #26a65b;
                            &:before {
                                content: '\f00c';
                            }
                        }
                        &.out-of-stock {
                            color: red;
                            &:before {
                                content: '\f00d';
                            }
                        }
                    }
                }
            }
        }
        .manufacture {
            padding: 20px 0;
            border-bottom: 1px solid #ccc;
            .title {
                display: block;
                margin-bottom: 5px;
            }
            a {
                display: block;
            }
        }
    }
    #tabs {
        @include cf();
        margin: 80px 0 0 0;
        a {
            display: block;
            float: left;
            padding: 0 20px;
            height: 35px;
            line-height: 35px;
            margin-left: 10px;
            background: $colorSenary;
            text-transform: uppercase;
            &:first-child {
                margin-left: 0;
            }
            &.selected,
            &:hover {
                background: $colorPrimary;
                color: #fff;
            }
        }
    }
    .tab-content {
        padding: 50px 0 30px;
    }
    form {
        &[name=friend],
        &#review-form {
            width: 500px;
            p {
                @include cf();
                margin: 0 0 20px 0;
                label {
                    display: block;
                    margin-bottom: 10px;
                }
                input, textarea {
                    width: 100%;
                }
                input[type=submit] {
                    margin-top: 10px;
                }
            }
        }
    }
    form#review-form {
        table {

        }
    }
}



.relatedProducts {
    margin: 40px 0 0 0;
    h3 {
        margin-bottom: 30px;
        font-size: 20px;
        text-align: center;
    }
}

@include responsive() {
    .productPage {
        .images {
            position: relative;
            width: 100%;
            $thumbnailsWidth: 180px;
            .image {
                @include border-box();
                float: left;
                width: calc(100% - #{$thumbnailsWidth + 20});

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .thumbnails {
                float: right;
                width: $thumbnailsWidth;
                .thumb {
                    float: none;
                    margin: 0 0 10px 0;
                    float: left;
                    &:nth-child(2n) {
                        float: right;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .info {
            width: 100%;
            margin-top: 40px;
        }
    }
}
@include responsive(750px) {
    .productPage {
        #tabs {
            a {
                @include border-box();
                width: 100%;
                margin: 0 !important;
                border-radius: 0;
                &:first-child {
                    border-radius: $borderRadius $borderRadius 0 0;
                }
            }
        }
        .tab-content {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
        form {
            &[name=friend],
            &#review-form {
                width: 100%;
            }   
        }
    }
}

@include responsive(500px) {
    .productPage {
        .images {
            position: relative;
            width: 100%;
            $thumbnailsWidth: 150px;
            .image {
                width: 100%;
            }
            .thumbnails {
                width: 100%;
                margin: 20px auto 0 auto;
                .thumb {
                    float: left !important;
                    margin: 0 10px 10px 0;
                }
            }
        }
        .info {
            width: 100%;
            margin-top: 40px;
            #add-to-cart {
                input[type=submit] {
                    width: 65%;
                }
            }
        }
    }
}
