@import 'properties';
@import 'reset';
@import 'lib/fontAwesome';
@import 'lib/fancybox';
@import 'lib/jquery-ui';

html, body {
    height: 100%;
}

body {
    font-size: $fontSize;
    line-height: $lineHeight;
    color: $fontColor;
    font-family: $fontPrimary;
    -webkit-text-size-adjust: 100%;
}

h1,h2,h3,h4,h5,h6 {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 1em;
    &.fa:before {
        padding-right: 10px;
    }
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 18px;
}

h4 {
    font-size: 16px;
}


a {
    @include transition(all 0.3s);
    color: inherit;
    text-decoration: inherit;
    font-weight: 500;
}

.fa {
    line-height: inherit;
    font-style: normal;
}

strong {
    font-weight: 500;
}

i, em {
    font-style: italic;
}


input[type=text],
input[type=number],
input[type=email],
input[type=password],
textarea {
    -webkit-appearance: none;
    border-radius: 0;
}

input[type=text],
input[type=date],
input[type=number],
input[type=password],
textarea {
    @include border-box();
    @include transition(all 0.3s);
    height: $inputHeight;
    border: none;
    -webkit-box-shadow: inset 0 0 0 1px $colorSenary;
    box-shadow: inset 0 0 0 1px $colorSenary;
    outline: 0;
    padding: 10px;
    font-family: $fontPrimary;
    font-size: 15px;
    color: $fontColor;
    &:focus {
        -webkit-box-shadow: inset 0 0 0 2px $colorSenary;
        box-shadow: inset 0 0 0 2px $colorSenary;
    }
}

textarea {
    height: 130px;
}

hr {
    border: none;
    border-top: 1px solid #ccc;
}

.button,
a.button,
input[type=submit],
button {
    @include transition(all 0.3s);
    @include border-box();
    @include appearance();
    display: inline-block;
    border-radius: $borderRadius;
    height: $buttonHeight;
    border: none;
    -webkit-box-shadow: inset 0 0 0 1px $colorPrimary;
    box-shadow: inset 0 0 0 1px $colorPrimary;
    outline: 0;
    background: #fff;
    padding: 0 20px;
    font-size: $fontSize;
    font-weight: 400;
    font-family: $fontPrimary;
    text-transform: uppercase;
    color: $colorPrimary;
    cursor: pointer;
    text-align: center;
    &:hover {
        opacity: 1;
        background: $colorPrimary;
        color: #fff;
    }
}

a.button {
    line-height: $buttonHeight;
}

.select {
    @include border-box();;
    position: relative;
    width: 118px;
    border: 1px solid #ccc;
    height: $inputHeight;
    line-height: $inputHeight;
    overflow: hidden;
    background: #fff;
    &:after {
        display: block;
        position: absolute;
        top: 0;
        right: 12px;
        font-family: FontAwesome;
        font-size: 14px;
        content: '\f0d7';
        color: #333;
        line-height: inherit;
        z-index: 0;
    }

    select {
        @include resetSelect();
        position: relative;
        height: 100%;
        padding-left: 10px;
        font-size: 14px;
        z-index: 1;
        color: $fontColor;
        cursor: pointer;
        font-family: $fontPrimary;
    }
}

#sb-site {
    height: 100%;
}

.page {
    //Sticky footer
    min-height: 100%; 
    height: auto !important;
    height: 100%;
    margin-bottom: $footerHeight * -1;
}

#footerPush {
    height: $footerHeight;
}

.mainContent {
    p {
        margin-bottom: 15px;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

.wrap {
    @include center();
}

.relative,
.por {
    position: relative;
}

.left,
.fl {
    float: left;
}

.right,
.flr {
    float: right;
}

.clear {
    clear: both;
}

.cf {
    @include cf();
}

.hide,
.hidden {
    display: none;
}

@include responsive() {
    .page {
        min-height: 0; 
        margin-bottom: 0;
    }

    #footerPush {
        display: none !important;
    }
}

.startPageRandomProductsWrapper {
    clear: both;
    float: left;
    width: 100%;

    .startPageRandomProduct {

        .startPageRandomProductCategory {
            clear: both;
        }
    }
}
