@import 'mixins';
/* $pagewidth: 1100px;
$footer_height: 454px;
$general_lineheight: 20px;
$general_fontsize: 13px;
$general_font_color: #1e1e1e;

$sans_serif: 'Lato', sans-serif;
$serif: 'Bree Serif', serif; */

// fonts
// Ubuntu
// Ubuntu-Medium

$pageWidth: 1180px;
$footerHeight: 750px;

$colorPrimary: #d61c7d;
$colorSecondary: #43bc40;
$colorTertiary: #ed7d23;
$colorQuaternary: #aaaaaa;
$colorQuinary: #cccccc;
$colorSenary: #e1e1e1;
$colorSeptenary: #f8f8f8;

$fontPrimary: 'Ubuntu', sans-serif;
$fontSecondary: fontPrimary;

$borderRadius: 17px;
$buttonHeight: 35px;
$inputHeight: 35px;

$fontSize: 15px;
$fontColor: #191919;
$lineHeight: 1.5em;

$split: false;
$responsive: true;
