@import 'properties';
.pageCategory {
    .subContent {
        .categoryHeader {
            width: 70%;
            margin: 0 auto;
            text-align: center;
            margin-bottom: 60px;
        }
    }
}

@include responsive(640px) {
    .pageCategory .subContent .categoryHeader {
        padding: 20px 0;
        width: 90%;
    }
}