@import 'properties';
.contactPage {
    @include cf();
    .leftContent {
        width: 46%;
        float: left;
    }

    .rightContent {
        width: 46%;
        float: right;
        p {
            margin-bottom: 20px;
            label {
                display: block;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                &[type=submit] {
                    margin-top: 10px;
                }
            }
            
            textarea {
                width: 100%;
                height: 150px;
            }

            .mandatory-symbol,
            .mandatory-description { 
                display: none;
            }
        }
    }
}

@include responsive(768px) {
    .contactPage {
        .leftContent,
        .rightContent {
            width: 100%;
        }
        .rightContent {
            margin-top: 30px;
        }
    }
}
